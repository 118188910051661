import { parsePhoneNumberFromString } from "libphonenumber-js/max";

export default class PhoneNumber {
  /**
   *
   * @param phoneNumString
   * @param normalizeWithDefaultCountryCode Set to default country code (for
   * example "+47") to trigger normalization to GroupAlert format +<country
   * code><phone number> where value of argument is used as default country code
   * if number does not contain country code.
   */
  constructor(phoneNumString, normalizeWithDefaultCountryCode = "") {
    this.phoneNum = parse(phoneNumString, normalizeWithDefaultCountryCode);
  }

  isValid() {
    return !!this.phoneNum;
  }

  /**
   * @returns {string|boolean} returns normalized number Returns false
   * on invalid phone number
   */
  getNormalized() {
    if (!this.phoneNum) {
      return false;
    }
    return this.phoneNum.number;
  }

  /**
   * @name getAsApiFormat
   *
   * Returns number in format used in V24 API
   * @param numberType {Number} 0 (unknown), 1 (work), or 2 (private)
   * @returns {{number: string, countryCode: string, type: string, numberType: Number}}
   * Example:
   * {
   *   countryCode: "+47",
   *   number: "56901480",
   *   type: "F",
   *   numberType: 1
   * }
   */
  getAsApiFormat(numberType = 1) {
    const props = this.getPhoneNumProperties();
    return {
      countryCode: "+" + props.countryCallingCode,
      number: props.nationalNumber,
      type: this.isMobile(true) ? "M" : "F",
      numberType: numberType
    };
  }

  getPhoneNumProperties() {
    return this.phoneNum;
  }

  isMobile(favourMobileIfUnsure) {
    /*
    Possible types:
    MOBILE
    FIXED_LINE
    FIXED_LINE_OR_MOBILE
    PREMIUM_RATE
    TOLL_FREE
    SHARED_COST
    VOIP
    PERSONAL_NUMBER
    PAGER
    UAN
    VOICEMAIL
     */
    const type = this.phoneNum.getType();
    if (type === "MOBILE") {
      return true;
    } else if (type === "FIXED_LINE_OR_MOBILE" && favourMobileIfUnsure) {
      return true;
    }
    return false;
  }
}

/**
 * @param phoneNumberStr
 * @param defaultCountryPrefix
 * @returns {PhoneNumber|Boolean} Returns PhoneNumber object. Returns false on
 * invalid phone number
 */
const parse = (phoneNumberStr, defaultCountryPrefix = "") => {
  if (!phoneNumberStr) {
    return false;
  }
  let num = phoneNumberStr.toString();
  num = removeWhitespace(num);
  num = convertLeadingZeroToPlus(num);
  num = addPrefix(num, defaultCountryPrefix);
  const parsed = parsePhoneNumberFromString(num);
  if (!parsed) {
    return false;
  }
  return parsed;
};

const removeWhitespace = phoneNumStr => {
  return phoneNumStr.replace(/\s/g, "");
};

const convertLeadingZeroToPlus = phoneNumStr => {
  if (phoneNumStr.substr(0, 2) === "00") {
    return "+" + phoneNumStr.substr(2);
  }
  return phoneNumStr;
};

const addPrefix = (phoneNumStr, countryCode) => {
  if (numStartsWithPlus(phoneNumStr)) {
    return phoneNumStr;
  }
  // phoneNumStr does not have international + prefix
  if (countryCode) {
    // Backwards compability to old Gruppevarsling: If the number is longer
    // then Norwegian national number (8 digits) and starts with "47", we accept
    // this
    // as +47<nationalNumber>
    if (
      countryCode === "+47" &&
      phoneNumStr.length === 10 &&
      phoneNumStr.substr(0, 2) === "47"
    ) {
      return "+" + phoneNumStr;
    }
    // It would be nice to do the same for all countries as done for
    // Norwegian numbers above, but numbers rules are in many countries much
    // less strict and it's hard, or impossible, to know if country code
    // or just the + should be added. So for non-Norwegian numbers we just
    // add any set country code.
    return countryCode + phoneNumStr;
  } else {
    return phoneNumStr;
  }
};

const numStartsWithPlus = phoneNumStr => {
  return phoneNumStr.substr(0, 1) === "+";
};
